<template>
    <div  >
        <topHead></topHead>
    
    <div class="wrap" >
        
        <p class="title">培训考试</p>            
        <p class="tips">退出前需要暂停视频以保存进度，无法跳转到上次学习进度时请保存当前进度后重新进入!</p>

        <div class="videoTitle">
            <span>点击视频开始学习</span>
            <span class="rate"> 学习进度：<b style="color: red;">{{ rate }}</b> %
                <!-- :class="showdatiBtn.class" -->
                <!-- <el-button type="primary" :class="showdatiBtn.class"  @click="startDati">开始答题</el-button> -->
            </span>

        </div>
        <div class="playVideo" style="margin-top: 10px;"> 
            
            <div :class="[fullflag?'fixedPosition':'normalPosition','container']"  ref="container" id="videoBox" >
                <!-- http://ycg-eocs.oss-cn-beijing.aliyuncs.com/article_images/2024/01/09/d93e0f7a-8e45-4564-8a1d-784ddeab31c0.mp4 -->
                <!-- query.videoUrl -->
                <video ref="videoSour" Metadata @play.once="handlePlay" @pause="handlePause" 
                @ended="handleEnded" @loadedmetadata="getVideoDura"  @loadstart="onLoadStart"
                @loadeddata="onLoadedData" :src="query.videoUrl" ></video>
                <div v-loading="isLoading" class="loading-indicator el-loading-spinner"  element-loading-text="视频加载中..."  ></div>
                <div class="control">
                    <i :class="videoClassForm.switchClass" id="switch" @click="switchBtn"></i>
                    <div class="process" @click="processLineBtn" ref="process">
                        <!-- <div ref="bar" class="bar"></div> -->
                        <div class="preload" ref="preload">
                            <!-- <div class="after" ref="after" @mousedown="dragProgressBar"></div> -->
                            <div class="after" ref="after"></div>
                        </div>
                    </div>
                    <div class="time">
                        <span>{{ currenttime }}</span> /
                        <span>{{ durationtime }}</span>
                    </div>
                    <!-- <span v-if="jumpShow" class="tip1">上次播放至{{stopTime}} <span class="jumpBtn" @click="jumpTime">跳转播放</span></span> -->
                    <div class="voice">
                            <img
                                v-show="voicePercent > 0"
                                @click.stop="voiceSwitch(true)"
                                src="../../assets/images/sound.png"
                                alt=""
                            />
                            <img
                                v-show="voicePercent <= 0"
                                src="../../assets/images/sound-no.png"
                                @click.stop="voiceSwitch(false)"
                                alt=""
                            />
                            <!-- 声音滚动条 -->
                            <div class="voiceBox">
                                <div class="num">{{ (voicePercent * 100).toFixed(0) }}%</div>
                                <div>
                                <div class="voice-bgc" ref="voiceBgc"></div>
                                <div class="voice-progress" ref="voiceProgress">
                                    <div class="after" @mousedown.stop="dragSoundProgressBar"></div>
                                </div>
                                </div>
                                <div class="voice-model" @click.stop="voiceClick"></div>
                            </div>
                    </div>
                    <i :class="fullClass.switchClass" id="full" @click="fullBtn"></i>
                </div>
            </div>
        </div>
        <!-- <img v-if="localVideoUrl" :src="localVideoUrl" alt="" style="width:200px;height:300px;">
        {{ localVideoUrl }} -->

<!-- <el-button @click="photograph()"></el-button> -->
    <!-- <draggable  class="drag-container"> -->
            <div class="camraWrap" :class="camraWrapClass.switchClass" >
                <!--开启摄像头-->
                    <!-- <img @click="callCamera" :src="headImgSrc" alt="摄像头"> -->

                    <!--canvas截取流-->
                    <div v-if="!isOKCamera" style="text-align: center;margin-top:20px;color: red;">
                        摄像头开启失败!
                        <p style="margin-top:20px;">请检查摄像头是否可用！</p>    
                    </div>
                    <div v-else>
                        
                        <!--图片展示-->
                        <video ref="carmerVideo" width="280" height="210" autoplay ></video>
                        <canvas ref="canvas" width="280" height="210" ></canvas>
                    </div>
                    <!--确认-->
                    <!-- <el-button size="mini" type="primary" @click="photograph"></el-button> -->
                    <!-- <el-button size="mini" type="primary" @click="closeCamera">关闭摄像头</el-button> -->
            </div>
        <!-- </draggable> -->
        <!-- <draggable> -->
            <!-- <div style="height:30px;width:30px;background:rebeccapurple" v-drag

            ></div> -->
        <!-- </draggable> -->
        <foot></foot>
        <randomTan @visibleTan="tanShow" @countPlus="tanClose" :isShow="showVisible" :key="time"></randomTan>
        <el-dialog
            title="提示"
            :visible.sync="dialogAlter"
            width="30%"
            :before-close="handleClose">
            <p style="height:30px;line-height:30px;color: red;;">由于您离开次数过多，将强制退出学习</p>
            <p style="height:30px;line-height:30px;color: #666;">正在退出学习。。。</p>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <!-- <el-button type="primary" @click="dialogAlter = false;$router.push('/record')">我要退出学习</el-button> -->
            </span>
            </el-dialog>
        <el-dialog
            title="提示"
            :visible.sync="randomAlter"
            width="30%"
            :before-close="handleClose">
            <p style="height:30px;line-height:30px;color: red;;">验证码已超过10分钟，将强制退出学习</p>
            <p style="height:30px;line-height:30px;color: #666;">正在退出学习。。。</p>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <!-- <el-button type="primary" @click="dialogAlter = false;$router.push('/record')">我要退出学习</el-button> -->
            </span>
        </el-dialog>

        <el-dialog
            title="温馨提示"
            :visible.sync="tipVisible"
            width="30%"
            :show-close="false"
            :center="true"
            :close-on-click-modal="false"
            >
            <ul>
                <!-- <li class="tipsItem" style="">
                    1.请点击F11，开启全屏模式，然后开始答题！
                </li> -->
                <li class="tipsItem" style="line-height:25px;">
                    学习过程中请保持摄像头对准学习人员，并保持光线充足，摄像头不定时抓拍学习人员学习状态！
                </li>
            </ul>
            <p style="margin-top:5px;width: 100%;height:30px;line-height:30px;background-color: #f1f1f1;color: #666;" v-if="Sencond > 0">请仔细阅读提示内容，<span style="color: #409eff;">{{Sencond}}</span>秒后方可关闭弹框</p>
            <span></span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="tipVisible = false;" :disabled="isDisabled">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="温馨提示"
            :visible.sync="jumpShow"
            width="30%"
            :show-close="false"
            :center="true"
            :close-on-click-modal="false"
            >
            <ul>
                <!-- <li class="tipsItem" style="">
                    1.请点击F11，开启全屏模式，然后开始答题！
                </li> -->
                <li class="tipsItem" style="line-height: 25px;text-align: center;: 30px;font-size: 20px;margin: 30px;">
                    <span  class="">上次播放至{{stopTime}} </span>
                </li>
            </ul>
            <p style="margin-top:5px;width: 100%;height:30px;line-height:30px;background-color: #f1f1f1;color: #666;" v-if="Sencond > 0">请仔细阅读提示内容，<span style="color: #409eff;">{{Sencond}}</span>秒后方可关闭弹框</p>
            <span></span>
            <span slot="footer" class="dialog-footer">
                
                <el-button type="info" @click="jumpShow = false;" >取消</el-button>
                <el-button type="primary" @click="jumpShow = false;jumpTime()" >跳转播放</el-button>
            </span>
        </el-dialog>
</div>
</div>
</template> 
<script>
import netTool from '../../api/netTool';
import foot  from '../../components/footer.vue';
import topHead  from '../../components/topHead.vue';
import randomTan  from '../../components/randomTan.vue';

import axios from '../../api/axios';
import OOS from '../../utils/oos-sdk-6.0.min'

import draggable from 'vuedraggable';

export default {
    data() {
        return {
            jinduVisible:false,
            tipVisible:false,
            dialogAlter:false,
            randomAlter:false,
            videoElement:null,
            canvasElement:null,
            isOKCamera:true,
            showdatiBtn:{class:'close'},
            showdatiWarp:{class:'close'},
            rate:0,
            headImgSrc: require('@/assets/images/camera.png'),
            imgUrl:null,
            // videoSrc:require('../../static/video/Part001.mp4'),
            videoSrcList: [
                // require('../../static/video/Part001.mp4'),
                // require('../../static/video/Part002.mp4'),
                // require('../../static/video/Part003.mp4'),
            ],
            currentVideo: '',
            video: undefined,
            videoClassForm: {
                switchClass: 'el-icon-video-play switch',
            },
            camraWrapClass:{
                switchClass:'close'
            },
            currenttime: '00:00:00',
            durationtime: '00:00:00',
            videoTime: undefined,
            barWidth: '',
            // num: 1,
            fullflag: false,
            fullClass:{
                switchClass:'el-icon-full-screen ',
            },
            answerList:{
                radio1:'',
                radio2:'',
                radio3:'',
                radio4:'',
            },
            query:{
                id:'',
                videoUrl:'',
                bokId:'',
                coverUrl:'',
                subjectId:''
            },
            bolbVideoUrl:null,
            learnId:'', // 学习记录Id
            timer:null,
            ossSts:{},
            s3: null,
            imgSrc:{},
            localVideoUrl:null,
            showVisible:false,
            count: 0,
            maxCount: 3,
            time:null,
            num:0, // 离开当前页面的次数
            messageAlter:[],
            RandomTime:null,
            videoTotal:null,
            timeoutId: null, //
            hasStoped:false,
            randomAlterTimer:null,
            jumpShow:false,
            stopTime:null,
            isStop:false,
            isLoading:false,
            Sencond: 8,//设置初始倒计时
            isDisabled: true,
            voicePercent:0.7,
            voicePercentCopy: 0.7, //音量备份 以便静音后回复
            flag: true, //拖动标杆
            jumpTimer:null,
            videoUrl:'',
            complete:false,
            complete:null,
            proBarX: 0, //进度条拖动x轴的位置
            proWidth: 100, //拖动的距离
            userType:localStorage.getItem('userType')
        }
    },
    directives: {
        'el-draggable': {
            bind(el, binding, vnode) {
                el.draggable = true
                el.addEventListener('dragstart', (event) => {
                event.dataTransfer.setData('text/plain', JSON.stringify(binding.value))
                })
            }
        }
    },
    components:{
        foot,
        topHead,
        randomTan,
        draggable
    },
    mounted() {
        this.video = this.$refs.videoSour;
        // this.getVideoDura(); 
        this.videoElement = this.$refs.carmerVideo;
        this.canvasElement = this.$refs.canvas;
        if(this.userType == '3'){ // 专家
            // this.isCurrentPage()
        }else if(this.userType == '7'){ // 会员
            this.isCurrentPage()
        }
        
        this.tipVisible = true;
       this.getSencond()
       this.$refs.voiceProgress.style.height = '30px';
       clearTimeout(this.timeoutId);
        console.log('加载完成')

    //    this.blob_load(this.videoUrl);
    //    this.video.addEventListener("progress", function() {
    //         // When buffer is 1 whole video is buffered
    //         if (Math.round(video.buffered.end(0)) / Math.round(video.seekable.end(0)) === 1) {
    //         // Entire video is downloaded
    //         }
    //     }, false);
    },
    updated() {
        this.video = this.$refs.videoSour;
        this.getVideoDura();
       
    },
    async created() {
        this.query.id = this.$route.query.id;
        // this.videoUrl = this.$route.query.videoUrl;
        this.query.videoUrl = this.$route.query.videoUrl;
        // this.blob_load(this.query.videoUrl);
        this.query.bokId = this.$route.query.bokId;
        this.query.coverUrl = this.$route.query.coverUrl;
        this.query.subjectId = this.$route.query.subjectId;
        this.pause = this.$route.query.pause;
        this.playback = this.$route.query.playback;
        this.hasStoped = this.$route.query.pause == 1 ? true : false; 
        this.complete = Number(this.$route.query.complete) == 1 ? true : false;
        // console.log(this.query)

        // this.videoSrc = this.videoSrcList[this.$route.query.index || 0]
        await this.getCtyunSTS(); // 确保 getCtyunSTS 完成后再继续
        // 请求接口，判断该视频是否暂停过
        this.$nextTick(() => {
            // 1.禁用右键菜单
            document.oncontextmenu = new Function("event.returnValue=false");
            // 2.禁用鼠标选中
            document.onselectstart = new Function("event.returnValue=false");
            // 3.禁止键盘F12键
            document.addEventListener("keydown", function (e) {
                if (e.key == "F12") {
                e.preventDefault(); // 如果按下键F12,阻止事件
                }
            });
        })

    },
    watch: {
        showVisible(newValue) {
            let that = this;
            if (newValue) {
                that.isStop = true;
                // 清除之前的定时器
                clearTimeout(that.timeoutId);
                // 设置新的定时器
                that.timeoutId = setTimeout(() => {
                    that.randomAlter = true 
                    clearInterval(that.timer);
                    clearInterval(that.RandomTime);
                    document.title = "考试培训系统";
                    window.onfocus = null;
                    window.onblur = null;
                    that.showVisible = false;
                // 10分钟后执行的操作
                    this.randomAlterTimer = setTimeout(() => {
                            clearTimeout(this.randomAlterTimer)
                            that.randomAlter = false 
                            that.$router.push('/record')
                            
                        }, 2000);
                    this.showVisible = false; // 可选：关闭弹框
                }, 10 * 60 * 1000);
            }else{
                that.isStop = false
                clearTimeout(that.timeoutId);
            }
        }
    },
    methods: {
        blob_load(src){
            const req = new XMLHttpRequest();
            let that = this;
            console.log('-=-=-=',src)
            req.open('GET', src, true);
            req.responseType = 'blob';
            req.onload = function () {
            // Onload is triggered even on 404
            // so we need to check the status code
            if (this.status === 200) {
                const videoBlob = this.response;
                const blobSrc = URL.createObjectURL(videoBlob); // IE10+
                // Video is now downloaded
                // and we can set it as source on the video element
                that.video.src = blobSrc ;
                
                console.log(blobSrc, 'blobSrc加载完毕');
                this.bolbVideoUrl = blobSrc;
            }
            };
            req.onerror = function () {
            // Error
            };
            req.send();
        },
        dragSoundProgressBar(){
            var event = event || window.event //获得鼠标的拖动事件
            //记录下当前的坐标点
            let y0 = event.clientY
            let that = this
            let height = that.$refs.voiceProgress.offsetHeight
            let time = null
            that.flag = false
            // console.log(height)
            //获得当前div在所包含的祖先的位置
            //用于记录当前声量的比例
            document.onmousemove = function(event) {
                var event = event || window.event //获得鼠标的拖动事件
                var dis = 0
                dis = event.clientY - y0
                that.$refs.voiceProgress.style.height = height + dis + 'px'
                //大于整体高度
                if (that.$refs.voiceProgress.offsetHeight >= that.$refs.voiceBgc.offsetHeight) {
                that.$refs.voiceProgress.style.height = '70%'
                that.voicePercent = 0
                //小于整体高度
                } else if (that.$refs.voiceProgress.offsetHeight <= 0) {
                that.$refs.voiceProgress.height = '0px'
                that.voicePercent = 1
                } else {
                //当前音量大小
                that.voicePercent =
                    1 - that.$refs.voiceProgress.offsetHeight / (that.$refs.voiceBgc.offsetHeight / 100) / 100
                }
                // 设置音量
                that.video.volume = that.voicePercent
            }
            document.onmouseup = function(event) {
                event.stopPropagation()
                // voicePercent = (voiceBarInner.offsetHeight / voiceBar.offsetHeight) * 100
                document.onmousemove = null
                if (time) clearTimeout(time)
                time = setTimeout(() => {
                that.flag = true
                }, 200)
                //清除全局up事件
                setTimeout(() => {
                document.onmouseup = null
                }, 300)
            }
        },
        voiceClick(){
            var event = event || window.event
            //当前的宽度加上滑动的距离
            this.$refs.voiceProgress.style.height = event.offsetY + 'px'
            this.voicePercent = 1 - event.offsetY / 100
            this.video.volume = this.voicePercent;
        },
        voiceSwitch(bol){
            if (bol) {
                //有声音
                this.voicePercentCopy = this.voicePercent
                this.voicePercent = 0
                this.$refs.voiceProgress.style.height = '70%'
            } else {
                //点击静音
                this.voicePercent = this.voicePercentCopy
                this.$refs.voiceProgress.style.height = (1 - this.voicePercent) * 100 + 'px'
            }
            this.video.volume = this.voicePercent
        },
       
        getSencond () {
            const that = this
            const interval = window.setInterval(function () {
                    --that.Sencond
                    if (that.Sencond === 0) {
                        that.isDisabled = false
                        window.clearInterval(interval)
                    }
            }, 1000)
        },
        onLoadStart() {
            // console.log('开始加载')
            this.isLoading = true;
        },
        onLoadedData() {
            // console.log('加载完成')
            this.isLoading = false;
        },

        //判断焦点是否在当前页面，防切屏
        isCurrentPage(){
            // let num = 0 ;
            let _this = this 
            window.onblur = function() { 
                document.title = "请继续学习"; 
                _this.num++ 
                // console.log(num) 
            } 
            window.onfocus = function() { 
                document.title = "正在学习中,请勿离开"; 
                if (_this.num  <= 6) { 
                    _this.$message.info("已离开当前页面" + _this.num + "次，离开七次将强制退出学习") 
                } 
                if (_this.num > 6) { 
                    // _this.exitScreen() 
                    _this.video.pause();
                    // _this.LearnRecord(0)  只保留学习完成的学习记录和暂停的,没学完退出就不保存学习记录了
                    // _this.count = 3;
                    // _this.timer = null;
                    clearInterval(_this.timer);
                    clearTimeout(_this.RandomTime);
                    clearTimeout(_this.timeoutId);
                    _this.showVisible = false;
                    //退出全屏      
                    _this.dialogAlter = true;
                    document.title = "考试培训系统";  
                    
                    let currentPageTimer = setTimeout(() => {
                        _this.dialogAlter = false;
                       clearTimeout(currentPageTimer);
                        _this.$router.push('/record');
                        
                    }, 2000);
                    // close = _this.$alert('由于您离开次数过多，将强制退出学习', '提示', {
                    //     showClose: false,confirmButtonText: '我要退出学习', callback: action => { 
                    //         // _this.examineEnd()
                    //         // _this.$message.success("交卷成功！") 
                    //         this.removeAlert(close)
                    //         _this.$router.push('/record')
                    //     } 
                    //     }); 
                    //     _this.messageAlter.push(close)
                        // console.log('0000000    ',_this.messageAlter)
                } 
            }
        },
        handleClose(){
            
        },
        handlePlay(){
            // console.log('正在播放')
            // this.showRandomAlert()
        },
        handlePause() {
            // console.log('Video is paused...');
        },
        handleEnded() {
            //  this.releaseVideoResources();//新加
            // console.log('Video has ended...');
        },
        releaseVideoResources() {
            const videoPlayer = this.$refs.videoSour;
            if (videoPlayer) {
                videoPlayer.pause();
                videoPlayer.removeAttribute('src'); // 释放视频资源
                videoPlayer.load();
            }
        },
        tanShow(){
            this.showVisible = true;
            // this.dialogVisible = Math.random() > 0.5;
        },
        tanClose(){
            this.showVisible = false;
            this.video.play();
            this.count++;
        },
        showRandomAlert(min,max) {
                // const delay = Math.random() * 100000; // 随机延迟时间，最大100秒
                const delay = this.getRandomInteger(min,max); // 随机延迟时间，最大100秒
                // console.log('delay',delay)
                this.RandomTime = setTimeout(() => {
                    this.showVisible = true;
                    this.video.pause()
                    this.time  = (new Date()).valueOf()
                    this.photograph() // 弹出验证码的同时截图保存
                    // if(this.count < 2){
                    //     this.showRandomAlert()
                    // }
                    clearTimeout(this.RandomTime)
                }, delay*1000);
        },
      
        getRandomInteger(min, max){
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        async getCtyunSTS() {
        try {
            const res = await netTool.getCtyun({bucket: 'confidential-information'});
            if (res.status === 0) {
            this.ossSts = res.data;
            if (res.data) {
                this.s3 = new window.OOS.S3({
                accessKeyId: res.data.accessKeyId,
                secretAccessKey: res.data.accessKeySecret,
                endpoint: 'https://oos-cn.ctyunapi.cn',
                signatureVersion: 'v2',
                apiVersion: '2006-03-01',
                s3ForcePathStyle: true,
                sessionToken: res.data.stsToken
                });
                this.stsReady = true; // 标志STS已准备就绪
            }
            } else {
            this.$notify.error({
                title: '错误',
                message: res.msg
            });
            this.$store.goLoginPage();
            }
        } catch (error) {
            this.$notify.error({
            title: '错误',
            message: error.message
            });
            this.$store.goLoginPage();
        }
        },
        jumpTime(){
            this.video.currentTime = this.playback;
            this.jumpShow = false;
            clearTimeout(this.jumpTimer)
        },
        // 调用摄像头
        callCamera() {
            // H5调用电脑摄像头API
            return new Promise((resolve, reject) =>{ 
                navigator.mediaDevices.getUserMedia({
                    video: true
                }).then(success => {
                    this.isOKCamera = true;
                    // 摄像头开启成功
                    this.$refs['carmerVideo'].srcObject = success
                    // 实时拍照效果
                    this.$refs['carmerVideo'].style.display = 'block'
                    this.$refs['carmerVideo'].play()
                    
                    // this.takeSnapshot3()
                    this.stopTime = this.getTime(this.playback)
                    if((this.hasStoped && this.isStop == false) || this.playback > 0){
                       this.jumpShow = true;
                        this.jumpTimer = setTimeout(()=>{
                            this.jumpShow = false;
                            clearTimeout(this.jumpTimer)
                        },60 * 1000) 
                    }
                    
                    
                    resolve(true)
                }).catch(error => {
                    this.$message.error('摄像头开启失败，请检查摄像头是否可用！');
                    this.isOKCamera = false;
                    // console.error('摄像头开启失败，请检查摄像头是否可用！')
                })
            }).catch(err =>{
                    reject()        
                }) 
            
        },
        //每3分钟截图一次
        takeSnapshot3(){
            let that = this;
            let delaySnapshot = this.getRandomInteger(13,15)
            // console.log(delaySnapshot)
            this.timer  = setInterval(()=>{
                this.photograph()
            },1*60*1000)
        },
        // 拍照
        photograph() {
            let ctx = this.canvasElement.getContext('2d')
            // 把当前视频帧内容渲染到canvas上
            ctx.drawImage(this.videoElement, 0, 0, 280, 210)
            // 转base64格式、图片格式转换、图片质量压缩
            this.imgUrl = this.canvasElement.toDataURL('image/jpeg');
            // this.canvasElement.toDataURL();
            
            // console.log('imgBase64',this.imgUrl)
            // 由字节转换为KB 判断大小
            // let str = this.imgUrl.replace('data:image/jpeg;base64,', '')
            // let strLength = str.length
            // let fileLength = parseInt(strLength - (strLength / 8) * 2)
            // // 图片尺寸  用于判断
            // let size = (fileLength / 1024).toFixed(2)

            
            const file = this.imgUrl
            const time = (new Date()).valueOf()
            const name = time + '.jpeg'
            const conversions = this.getFileByBase64(file, name)
            // console.log('conversions',conversions)
            const data = new FormData()
            data.append('file', conversions)
            // var oGrayImg = canvas.toDataURL('image/jpeg')// 截取后的视频封面
            // const file = this.getFileByBase64(this.imgUrl)
            // console.log('imgfilename', file)
            // console.log('data-----',data)
                this.beforeAvatarUpload(conversions)
            // console.log(size)

            // 上传拍照信息  调用接口上传图片 .........

            // 保存到本地
            // this.dialogCamera = false
            // let ADOM = document.createElement('a')
            // ADOM.href = this.imgUrl
            // ADOM.download = new Date().getTime() + '.jpeg'
            // ADOM.click()
        },
       
        beforeAvatarUpload(file) {
            // console.log('00',file)
            const fileName = this.ossSts.filePath + encodeURIComponent(file.name);
            const uploadParams = {
                Bucket: this.ossSts.bucket,
                Key: fileName,
                Body: file,
                ContentType: "application/pdf"
            };

            this.s3.putObject(uploadParams).on('httpUploadProgress', (progress) => {
                // this.uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            }).send((err, data) => {
                if (err) {
                this.$message.error(`文件上传失败: ${err.message}`);
                } else {
                    var params = {
                        Bucket: this.ossSts.bucket,
                        Key: fileName,
                    };
                    this.localVideoUrl = this.s3.getSignedUrl('getObject', params);
                    // console.log('this.localVideoUr',this.localVideoUrl)
                    // let newFile = {
                    //     name: file.name, // 返回的文件名
                    //     url: fileName, // 在 computed 属性中生成签名 URL
                    // };
                    // this.$emit('update-file-list', [...this.fileList, newFile]);
                    // 保存文件
                    this.saveImg(fileName)
                    // this.$message.success(`文件上传成功`);
                }
            });
        },
            //   base64 转 file----------------------begin
        getFileByBase64(data,name) {
            const blobData = this.dataURLtoBlob(data)
            return this.blobToFile(blobData,name)
        },
        // 1,先将base64转换为blob
        dataURLtoBlob(dataurl) {
            const arr = dataurl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new Blob([u8arr], {
                type: mime
            })
        },
        // 2,再将blob转换为file
        blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date() // 文件最后的修改日期
            theBlob.name = fileName // 文件名
            return new File([theBlob], fileName, {
                type: theBlob.type,
                lastModified: Date.now()
            })
        },
        //   base64 转 file--------------------------end
        // 关闭摄像头
        closeCamera() {
            if (!this.$refs['carmerVideo'].srcObject) {
                this.dialogCamera = false
                return
            }
            let stream = this.$refs['carmerVideo'].srcObject
            let tracks = stream.getTracks()
            tracks.forEach(track => {
                track.stop()
            })

            this.$refs['carmerVideo'].srcObject = null
            this.$refs.carmerVideo.style.display = 'none';
            // console.log('关闭摄像头')
            this.camraWrapClass.switchClass = 'close'
            // this.timer = null;
            clearInterval(this.timer);

        },

        // 获取视频总时长
        getVideoDura() {
            var vid = this.$refs.videoSour;
            // console.log('vid',vid)
            // setTimeout(() => {
                this.videoTotal = vid.duration;
                this.durationtime = this.getTime(vid.duration);
                // console.log(';;;;;;',total,this.durationtime)
            // }, 5000);
        },
        // 播放视频的开关
        async switchBtn() {
            let video = this.video;
            if (video.paused) {
                this.getVideoCurrent();
                
                this.videoClassForm.switchClass = 'el-icon-video-pause switch';
                this.camraWrapClass.switchClass = 'open' // 开启摄像头面板
                let result = await this.callCamera() // 打开摄像头
                this.isStop = false;
                // this.callCamera() 
                if(result){
                    video.play();
                    // console.log('000')
                    this.LearnRecord(0)
                }
                
            } else {

                // if(this.hasStoped == false) { // 没有暂停过
                    video.pause();
                    // this.hasStoped == true;
                    this.isStop = true;
                    clearInterval(this.videoTime);
                    this.videoClassForm.switchClass = 'el-icon-video-play switch'
                    this.LearnRecord(0,1,Math.floor(this.video.currentTime),this.rate)
                // }else{ // 暂停过
                    // this.LearnRecord(0,1,Math.floor(this.video.currentTime),this.rate)
                // }
            }

            // if(this.hasStoped){ // 该视频暂停过
            //     this.$message.error(`该视频只允许手动暂停一次！`);
            // }
        },

        // 获取视频播放的进度
        getVideoCurrent() {
            var that = this;
            // console.log(that.$refs.process.offsetWidth)
            this.videoTime = setInterval(function () {
                if (that.getTime(that.video.currentTime) == that.durationtime) { // 播放完毕
                    // console.log(that.getTime(that.video.currentTime),  that.durationtime, '===》相等时关闭摄像头')
                    that.videoClassForm.switchClass = 'el-icon-video-play switch';
                    that.$refs.preload.style.width = '100%'
                    that.currenttime = that.durationtime
                    clearInterval(that.videoTime);
                    that.isOKCamera ? that.closeCamera() : ''
                    that.camraWrapClass.switchClass = 'close' // 关闭摄像头面板
                    that.rate = 100;  // 播放进度100%
                    that.showdatiBtn.class = 'open1';
                    that.LearnRecord(1,0,Math.floor(that.video.currentTime),100)
                    window.onfocus = null;
                    window.onblur = null;
                    document.title='学习完成！';
                    that.$message.success(`恭喜你，学习完成！`);
                    // that.timer = null;
                    clearInterval(that.timer);
                    clearTimeout(that.RandomTime);

                } else {
                    // console.log('未播放完',that.flag)
                    if(that.flag){
                        that.currenttime = that.getTime(that.video.currentTime);
                        if(that.$refs.process){
                            that.barWidth = that.$refs.process.offsetWidth * that.video.currentTime / that.video.duration;
                        }
                        if(that.$refs.preload){
                            that.$refs.preload.style.width = that.barWidth  + 'px';
                        }
                        that.rate = Math.round(that.video.currentTime * 100 / that.video.duration)
                    }  
                }

                const third = Math.floor(that.videoTotal / 3);
                // console.log(that.video.currentTime,that.videoTotal,that.videoTotal / 3,third)
                if(that.video.currentTime >= 1 - 0.5 && that.video.currentTime <= 1 + 0.5){
                    // if(that.complete) return
                    that.showRandomAlert(1,third-1)
                }
                
                // 当视频播放到1/3处时，弹出对话框
                if (that.video.currentTime >= third - 0.5 && that.video.currentTime <= third + 0.5) {
                    // console.log('视频已播放到1/3处！');
                    // if(that.complete) return
                    that.showRandomAlert(1,third-1)
                }
                if(that.video.currentTime >= third*2 - 0.5 && that.video.currentTime <= third*2 + 0.5){
                    // console.log('视频已播放到2/3处！');
                    // if(that.complete) return
                    that.showRandomAlert(1,third-1)
                }
                if((Math.floor(that.video.currentTime)  % 180) == 0 && that.video.currentTime > 2){
                    if(that.getTime(that.video.currentTime) < that.durationtime){
                        that.LearnRecord(0,0,Math.floor(that.video.currentTime),that.rate)
                    }
                }
            }, 1000);
        },
        // 进度条点击
        processLineBtn(e) {
            // this.num += 1;
            // if (this.num > 1) {
            //     localStorage.setItem('barWidth', this.barWidth);
            // }
            // var losW = this.barWidth;
            // if (localStorage.getItem('barWidth')) {
            //     losW = parseFloat(localStorage.getItem('barWidth'))
            // }
            // if (e.offsetX < losW) { // 
            //     this.currenttime = this.getTime(this.video.duration * e.offsetX / this.$refs.process.offsetWidth);
                // this.video.currentTime = this.video.duration * e.offsetX / this.$refs.process.offsetWidth
            // } else if (e.offsetX > this.barWidth && e.offsetX < losW) {
            //     this.currenttime = this.durationtime * e.offsetX / this.$refs.process.offsetWidth;
            // } else 
            if(this.complete){
                this.video.currentTime = this.video.duration * e.offsetX / this.$refs.process.offsetWidth
            }else{
                if (e.offsetX > this.barWidth) {
                    this.$notify.error({
                        message: '视频不允许快进哦！'
                    });
                    return;
                }
            }
            
        },
           //进度条拖动
        dragProgressBar(event) {
            if(!this.complete) return
            var event = event || window.event
            //得到按下时与x轴的距离
            this.proBarX = event.clientX
            let that = this
            let time = null
            //得到当前视频播放的长度
            that.proWidth = that.$refs.preload.clientWidth
            that.flag = false //拖动时警用调其它事件 如正在播放updateTime事件
            //按下滑动
            
            document.onmousemove = function(event) {
                var event = event || window.event
                //拖动的x轴减去按下的x轴 就等于拖动的距离
                let dis = event.clientX - that.proBarX
                //当前的宽度加上滑动的距离就等于最新的距离
                console.log('----',that.proWidth , dis)
                that.$refs.preload.style.width = that.proWidth + dis + 'px'
                // console.log(that.$refs.preload.offsetWidth, that.$refs.process.offsetWidth)
                //如果滑动的距离大于总距离 就直接填充为百分百
                if (that.$refs.preload.offsetWidth >= that.$refs.process.offsetWidth + 6) {
                // that.$refs.preload.style.width = '100%'
                // that.proWidth = dis
                // that.proBarX = that.proBarX
                }
                //计算出当前时间
                let durationPercent = that.$refs.preload.offsetWidth / that.$refs.process.offsetWidth
                // console.log('00000',that.videoTotal ,  durationPercent,that.durationtime * durationPercent)
                console.log('.....',that.video.currentTime)
                console.log('.....',that.currenttime)

                that.video.currentTime = that.videoTotal * durationPercent
                // that.videoInfo.playTimeText = that.changeSecondsToHours(that.$refs.videoBox.currentTime)
            }
            //鼠标抬起
            document.onmouseup = function(event) {
                event.stopPropagation()
                //存储当前的正在播放进度条的宽度
                if (that.$refs.preload) that.proWidth = that.$refs.preload.clientWidth
                //清除悬浮事件
                document.onmousemove = null
                //恢复其它事件
                if (time) clearTimeout(time)
                time = setTimeout(() => {
                that.flag = true
                }, 200)
                //清除全局up事件
                setTimeout(() => {
                document.onmouseup = null
                }, 300)
            }
        },
        // 全屏按钮
        fullBtn() {
            if (this.fullflag) {
                this.fullflag = false;
                this.$refs.container.style.height = '580px';
                // this.$refs.container.style.width = '100vw';
                // this.$refs.container.style.position = 'fixed'
                // this.$refs.container.style.zIndex = '10'
                // this.$refs.container.style.margin = '0'; // 取消默认边距
                // this.$refs.container.style.padding = '0';
                this.video.style.width = '100%';
                this.video.style.height = '100%';
                this.fullClass.switchClass = 'el-icon-full-screen'
               
            } else {
                this.fullflag = true;
                this.$refs.container.style.height = '100%';
                this.video.style.width = '100%';
                this.video.style.height = '100%';
                this.fullClass.switchClass = 'el-icon-copy-document'
              
            }
        },
        getTime(time) {
            var hours = Math.floor(time % 86400 / 3600);
            var minutes = Math.floor(time % 86400 % 3600 / 60);
            var seconds = Math.floor(time % 60);
            hours = hours >= 10 ? hours : '0' + hours;
            minutes = minutes >= 10 ? minutes : '0' + minutes;
            seconds = seconds >= 10 ? seconds : '0' + seconds;

            var str = '';
            str = hours + ':' + minutes + ':' + seconds;
            return str;
        },
        // 点击开始答题
        startDati(){
            // this.showdatiWarp.class = 'open'
            this.$router.push({path:'/answer',query:{bokId:this.query.bokId,learningId:this.learnId,subjectId:this.query.subjectId}})
        },
        // 学习记录
        LearnRecord(isComplete,isPause,playbackTime,playrate){
            let params ={
                id: this.learnId || '' ,
                platformId: this.$store.state.pfId,
                expertUuid: localStorage.getItem('expertUuid'),
                bokId: this.query.bokId,
                subjectId: this.query.subjectId,
                lessonId: this.query.id,
                complete:isComplete,
                category:localStorage.getItem('userType') == '3' ? 1: 2
                // pause:isPause,
                // playback:playbackTime
            }
            if(isPause){
                params.pause = isPause
            }
            if(playbackTime){
                params.playback = playbackTime
            }
            if(playrate){
                params.playbackPercent = playrate
            }
            netTool.learnRecord(params).then(res =>{
                // console.log(res)
                // localStorage.setItem('learnId',res.data.id)
                this.learnId = res.data.id
            })

        },
         // base64图片转file的方法（base64图片, 设置生成file的文件名）
        base64ToFile(base64, fileName) {
            // 将base64按照 , 进行分割 将前缀  与后续内容分隔开
            const data = base64.split(',')
            // 利用正则表达式 从前缀中获取图片的类型信息（image/png、image/jpeg、image/webp等）
            const type = data[0].match(/:(.*?);/)[1]
            // 从图片的类型信息中 获取具体的文件格式后缀（png、jpeg、webp）
            const suffix = type.split('/')[1]
            // 使用atob()对base64数据进行解码  结果是一个文件数据流 以字符串的格式输出
            const bstr = window.atob(data[1])
            // 获取解码结果字符串的长度
            let n = bstr.length
            // 根据解码结果字符串的长度创建一个等长的整形数字数组
            // 但在创建时 所有元素初始值都为 0
            const u8arr = new Uint8Array(n)
            // 将整形数组的每个元素填充为解码结果字符串对应位置字符的UTF-16 编码单元
            while (n--) {
                // charCodeAt()：获取给定索引处字符对应的 UTF-16 代码单元
                u8arr[n] = bstr.charCodeAt(n)
            }
            // 利用构造函数创建File文件对象
            // new File(bits, name, options)
            const file = new File([u8arr], `${fileName}.${suffix}`, {
                type: type
            })
            // 将File文件对象返回给方法的调用者
            return file
        },
        saveImg(url){
            let params={
                'type':1, // 1培训
                "learningId": this.learnId,
	            "oosUrl": url
            }
            if(this.isStop){
                params.pause = 1
            }else{
                params.pause = 0
            }
            netTool.saveLearningImage(params).then(res => {
                if(res.status == 0){
                    this.$message.success(`文件保存成功`);
                }
            })
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
            // console.log('3分钟截图定时器已清除');
        }
        if(this.videoTime){
            clearInterval(this.videoTime);
            this.videoTime = null;
            // console.log('视频播放定时器已清除');
        }
        if(this.RandomTime){
            clearTimeout(this.RandomTime); 
            this.RandomTime = null; 
            // console.log('随机验证码定时器已清除');
        }
        if(this.randomAlterTimer){
            clearTimeout(this.randomAlterTimer); 
            this.randomAlterTimer = null 
            // console.log('离开次数过多2秒定时器已清除');
        }
        if(this.timeoutId){
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
            // console.log('10分钟跳出器已清除');
        }
        window.onblur = null;
        window.onfocus = null;
        next();
    },
    beforeDestroy(){
        let video = this.video;
        if (!video.paused) {
            video.pause();
            clearInterval(this.videoTime);
            this.videoClassForm.switchClass = 'el-icon-video-play switch'
            // this.LearnRecord(0)
            clearInterval(this.timer)
           
        }
        // this.switchBtn()
        this.isOKCamera ? this.closeCamera() : ''
        this.camraWrapClass.switchClass = 'close' // 关闭摄像头面板 
        this.timer = null;
    },
    destroyed(){
        this.timer = null;
        clearInterval(this.timer);
        // window.localStorage.setItem('learnId','')
        
    }
}
</script>
<style scoped lang="scss">
.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrap{
    width: 1200px;
    margin:0 auto;
}
.title{
    font-size:16px;
    font-weight:bold;
    color:#333;
    height:40px;
    line-height:40px;
    border-bottom:1px solid #ddd;
    position: relative;
    padding-left:12px;

    &::after{
        position: absolute;
        content: '';
        width:4px;
        height:16px;
        background-color:#409eff;
        top:12px;
        left:0;
        border-radius: 4px;;
    }
}

.videoTitle{
    height:40px;
    line-height:40px;
    margin-bottom:10px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    font-size:18px;
    .rate{
        color: #333;
        font-weight: bold;
    }
}
.container {
    position: relative;
    width: 100%;
    min-height: 580px;
    background-color: pink;
}

.container video {
    width: 100%;
    height: 550px;
    background-color: #f9f0f2;
    object-fit: scale-down;
}
.container  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* left: 3px; */
    bottom: 0px;
    width: 100%;
    height: 40px;
    line-height:40px;
    margin: 0 auto;
    // border-radius: 5px;
    background: #fff;
}
.tip1{
    position:absolute;
    left:0;
    bottom:35px;
    width:240px;
    height:40px;
    line-height:40px;
    background: #333;
    color: #fff;
    font-size: 16px;
    text-align: center;
    // opacity: 0;
    transition: all .5s;
    .jumpBtn{
        margin-left:8px;
        color: #409eff;
        cursor: pointer;

    }

}
.control .switch {
    margin: 0 2px;
}
.control .icon {
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}
.control .process {
    width: 100%;
    height: 10px;
    border-radius: 4px;
    background-color: #ddd;
    overflow: hidden;
    cursor: pointer;

}
.control .process .bar {
    width: 0px;
    height: 100%;
    background-color: red;
}
.control .time {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    margin: 2px;
}
.control .full {
    cursor: pointer;
    margin-right: 2px;
}
video::-webkit-media-controls,
    video::-moz-media-controls,
    video::-webkit-media-controls-enclosure{
    display:none !important;
}



video::-webkit-media-controls-panel,
video::-webkit-media-controls-panel-container,
video::-webkit-media-controls-start-playback-button {
    display:none !important;
    -webkit-appearance: none;
}
video::-webkit-media-controls-panel,
video::-webkit-media-controls-panel-container,
video::-webkit-media-controls-start-playback-button {
    display:none !important;
    -webkit-appearance: none;
}

.camraWrap{
    position: fixed;
    bottom:40px;
    right:0;
    width:280px;
    height:210px;
    z-index:2000;
    background-color: #f1f1f1;
    overflow: hidden;
}
.open{
    display: block;
}
.open1{
    display: inline-block;
}
.close{
    display: none;
}
.kaoshi{
    margin-top: 20px;
}
.answerWrap{
    margin-top:10px;
    font-size:14px;
    .itemTitle{
        font-size:16px;
        font-weight: 600;
        height:30px;
        line-height: 30px;
        margin-top:20px;
        margin-bottom:5px;
    }
}

.voice {
        cursor: pointer;
        position: relative;
        margin-right:4px;
        margin-left:4px;
        &:hover .voiceBox {
          display: block;
        }
        img{
            display: inline-block;
            width: 24px;
            height:24px;
            vertical-align: middle;
        }
        .voiceBox {
          position: absolute;
          bottom: 24px;
          left: -4px;
          width: 40px;
          height: 140px;
          background-color: rgba(0, 0, 0, 0.8);
          display: none;
          border-radius: 4px;
          z-index:9999;
          .num {
            text-align: center;
            line-height: 30px;
            color: #fff;
          }
          > div > div {
            position: absolute;
            left: 48%;
            top: 34px;
            width: 3px;
          }
          .voice-bgc {
            background: #0292d7;
            height: 70%;
          }
          .voice-model {
            position: absolute;
            left: 48%;
            top: 34px;
            width: 3px;
            height: 70%;
            background: rgba(0, 0, 0, 0);
            z-index: 999;
          }
          .voice-progress {
            background: #fff;
            height: 70%;
            .after {
              position: absolute;
              width: 10px;
              height: 10px;
              border-radius: 5px;
              z-index: 9999;
              background: #fff;
              bottom: -4px;
              right: -4px;
            }
          }
        }
    }
    .fixedPosition{
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .normalPosition{
        position: relative;
        width: 100%;
        min-height: 580px;
        background-color: pink;
    }
    .preload {
      width: 0px;
      background: red;
      height: 20px;
      position: relative;
      .after {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        z-index: 9999;
        background: #fff;
        top: 0px;
        right: -5px;
      }
    }
    .tips{
        height: 50px;
        line-height:50px;
        background: rgb(217, 236, 255);
        text-align: center;
        font-size: 28px;
        color: red;
        margin: 20px 0;
        font-weight: bold;
    }

</style>
    