<template>
    <div>
      <!-- <el-button @click="randomPopup">随机弹框</el-button> -->
      <el-dialog :visible.sync="isShow" title="拖动滑块验证" width="450px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" @close="closeIt">
        
        <slide-verify ref="slideblock" :l="42" :r="10" :w="410" :h="155" :accuracy="accuracy" :time="time" show slider-text="向右滑动" :imgs="imgs"  @success="onSuccess" @fail="onFail" @refresh="onRefresh" class="sliderBox"></slide-verify>
        <p :class="[isSceesss==true?'yzMessage':'failMessage']">{{msg}}</p>
      </el-dialog>
    </div>
  </template>
   
  <script>
   // 验证码图片
        import img0 from '../assets/image/1.jpg';
        import img1 from '../assets/image/2.jpg';
        import img2 from '../assets/image/3.jpg';
        import img3 from '../assets/image/4.jpg';
        import img4 from '../assets/image/5.jpg';
        import img5 from '../assets/image/6.jpg';
        import img6 from '../assets/image/7.jpg';
        import img7 from '../assets/image/8.jpg';
        import img8 from '../assets/image/9.jpg';
        import img9 from '../assets/image/10.jpg';
        import img10 from '../assets/image/11.jpg';
        import img11 from '../assets/image/12.jpg';
        import img12 from '../assets/image/13.jpg';
        import img13 from '../assets/image/14.jpg';
        import img14 from '../assets/image/15.jpg';
        import img15 from '../assets/image/16.jpg';
        import img16 from '../assets/image/17.jpg';
        import img17 from '../assets/image/18.jpg';
        import img18 from '../assets/image/19.jpg';
        import img19 from '../assets/image/20.jpg';
  export default {
    data(){
        return{
            accuracy: 5,
            msg:'',
            isSceesss:null,
            imgs: [img0,img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19],
        }
    },
    props:{
        isShow:{
            type:Boolean,
            default:()=>{ return false},
        },
        time:{
            type:Number,
            default:() =>{ return 6891269847315}
        }
    },

   
    methods: {
        closeIt(){
            // this.$emit('countPlus',false)
        },
        // 验证码
        onSuccess(){
            this.$emit('countPlus',false)
            // this.msg = '验证成功';
            this.msg= '';
            this.isSceesss=null;
        },
        onFail(){
            this.isSceesss=false;
            this.msg = '验证失败';
        },
        onRefresh(){
            this.msg = '';
        },
}
  };
  </script>
  <style>
  .yzMessage {
    /* display: inline-block;
    position: absolute;
    top: 86%;
    left: 82%; */
    color: #52ccba;
    font-weight: 600;
    font-size: 20px;
    height: 30px;
    /* width: 30px; */
    margin-top:10px;
  }
  .failMessage {
    /* display: inline-block;
    position: absolute;
    top: 85%;
    left: 82%; */
    color: #f57a7a;
    font-weight: 600;
    font-size: 20px;
    height: 30px;
    /* width: 30px; */
    margin-top:10px;
  }
  .el-dialog__header {
    background: #002a52;
    text-align: center;
    }
    .el-dialog__title {
    color: aliceblue;
    }
</style>